export default {
  translation: {
    autocomplete: {
      enterText: 'Enter text…',
    },
    copyable: {
      copy_to_clipboard: 'Copy to clipboard',
      copied: 'Copied!',
    },
    typoreporter: {
      header: 'Report a mistake on the page',
      messageLabel: 'There is a mistake in the following text:',
      commentLabel:
        '<div class="mb-3">Since the error message form is one-sided, we are unable to reply. Ask a question in the Discussions if you want feedback on the lesson</div><strong class="mb-2">Do you want to send a notice to a webmaster?</strong>',
      send: '<strong>Send</strong>',
      sending: 'Sending',
      cancel: 'Cancel',
      error: 'Error! Something went wrong...',
    },
    links: {
      hexletHowToDebugHelpUrl: 'https://help.hexlet.io/article/20943',
      hexletAskQuestionsHelpUrl: 'https://help.hexlet.io/article/20939',
      hexletCodeReviewHelpUrl: 'https://help.hexlet.io/article/20935',
      hexletFaqHelpUrl: 'https://help.hexlet.io/article/20941',
      hexletMarkdownGuideUrl: 'https://guides.hexlet.io/ru/markdown/',
    },
    please_wait_html:
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="d-none d-md-inline">&nbsp;Wait...</span>',
    errors: {
      email: {
        invalid_format: 'Invalid format',
      },
    },
    card_form: {
      errors: {
        payment_declined:
          'The payment was declined by your bank. This happens if your account lacks funds or your card cannot be used for online payments',
        access: 'You need to log in to your Hexlet account',
        network:
          'A network error occurred (code: {{code}}). Check your Internet connection and try again. If the error keeps repeating and the network is available, click on “Help” and email us',
      },
      fields: {
        card: {
          label: 'Debit/credit card data',
        },
        agreement: {
          label:
            'I agreed to <a href="https://hexlet.io/pages/tos" class="text-decoration-none fw-bold" target="_blank"  rel="nofollow">Service Conditions</a>',
        },
        name: {
          label: 'Cardholder name',
          help: "As it's written on the card",
        },
      },
    },
    card: {
      on_success: {
        header: 'The card has been successfully changed!',
        explanation:
          'Next time the money will be charged to a new card. You do not need to do anything else',
        next: 'Continue',
      },
    },
    company_payment: {
      on_success: {
        header: 'Invoice has been paid!',
        explanation: 'The money will be received in a few minutes',
        next: 'Continue',
      },
      card_form: {
        fields: {
          submit: {
            label: '{{amount}} {{currency.symbol}} to pay',
          },
        },
      },
    },
    changing_card: {
      card_form: {
        fields: {
          submit: {
            label: 'Update',
          },
        },
      },
    },
    subscription: {
      on_success: {
        header: 'Congrats!',
        explanation: 'You now have full access to Hexlet content',
        next: 'Start learning',
      },
      card_form: {
        fields: {
          submit: {
            price_professional_label: {
              month: '{{amount}} per month',
              year: '{{amount}} per year',
            },
            price_profession_label: {
              month: '{{amount}}',
              year: '{{amount}}',
            },
            price_premium_label: {
              month: '{{amount}} per month',
              year: '{{amount}} per year',
            },
          },
        },
      },
    },
    community: {
      disabled: 'Topic is closed',
      disabled_discription:
        'This topic has been automatically closed for comment because an issue was discovered and has been successfully resolved. If you have a similar problem or question, please create a new topic and we will be happy to help you! Thanks for understanding.',
      flashes: {
        error:
          'Oops! Something went wrong. Probably a network problem. Try again.',
        mentor_assignment_conflict:
          'This topic is currently taken up by another mentor. Refresh the page to see the changes',
      },
      validation: {
        required: 'Required field',
      },
      buttons: {
        like: 'Like',
        unlike: 'Unlike',
        usefull: 'Mark as useful',
        markdown_help: 'About a Markdown',
        how_to_ask_question: 'How ask a question correctly',
        help: 'Stuck with exercise',
        create_topic: 'Create topic',
        unmark_as_solution: 'Unmark',
        mark_as_solution: 'Mark as solution',
        reply_to: 'Reply',
        cancel_edit_topic: 'cancel',
        update_topic: 'save',
        delete_topic: 'Delete',
        cancel_remove_topic: 'Cancel',
        apply_remove_topic: 'Delete',
        settings: 'Settings',
        edit_topic: 'Edit',
        cancel: 'Cancel',
        create_comment: 'Add a comment',
        hide_comments: 'Hide comments',
        assign_mentor: 'Become a mentor',
        unsubscribe_from_topic: 'Unsubscribe',
        subscribe_to_topic: 'Subscribe',
        show_comments: 'View comments ({{count}})',
        save: 'Save',
        saving: 'Saving...',
        saved: 'Saved',
        link_to_github: 'Link to github',
      },
      log_in_for_adding_topic: 'Log in to comment',
      placeholders: {
        write_question: 'Add a new topic…',
        write_comment: 'Add a comment…',
      },
      help: {
        code_review_html:
          'To ask a question about an exercise, first, submit your <a href="{{link}}" target="_blank" rel="noopener">saved solution</a>. Please avoid posting the bare code in the "Discussion" section so that not to reveal the solution to other students',
      },
      mentor: 'Mentor: {{full_name}}',
      waiting_for_mentor_response: 'Waiting for mentor response',
      marked_as_solution: 'Marked as solution',
      ts: 'TS',
      new: 'New',
      mentor_title: 'Hexlet support team',
      mentor_badge: 'Hexlet',
      hexlet_support_badge: 'Support',
      student_badge: 'Student',
      hidden_topic:
        'The topic is hidden because technical support in the “Discussions” does not analyze or review the code',
      need_answer: 'Need answer',
      need_answer_title:
        'There are more than 10 topics in the resource associated with the topic',
      topic_has_solution: 'Decided',
      ts_title: 'Topic starter',
      choose_mentor: 'Assign a mentor',
      question_kinds: {
        question: 'Question',
        gratitude: 'Gratitude',
        common: 'Other',
        bug: 'Report a bug',
        aggression: 'Report an aggression',
        code: 'Code',
        community: 'Community',
      },
      question_mentorship_states: {
        opened: 'Opened',
        closed: 'Closed',
      },
    },

    block_of_code: 'Code (possible spoiler)',
    markdownEditor: {
      buttons: {
        edit: 'Edit',
        preview: 'Preview',
      },
    },
    web: {
      communities: {
        btn: {
          expand: 'expand',
          collapse: 'collapse',
        },
      },

      courses: {
        lessons: {
          exercise_unit: {
            loading_error:
              'Failed to connect to the exercise server. We are already aware of the problem, try to refresh the page or do <a data-confirm="{{ confirm }}" href="{{ link }}" class="alert-link" data-method="delete">a reset</a>',
            not_loading_html:
              '<a data-confirm="{{ confirm }}" href="{{ link }}" class="link-light" data-method="delete">Click here</a> if the exercise fails to load',
            run: 'Run tests',
            next: 'Next',
            need_solution: 'You have to solve the exercise to proceed',
            web_url: 'Web access',
            test_url: 'Test UI',
            confirm_reset:
              'You are about to reset your exercise progress. Your code will be lost. Copy it somewhere else if you need it. Continue with the reset?',
            confirm_view_solution:
              "You can look up the teacher's solution, but we won't give you points for this exercise. Points are only given for solving the exercise by yourself. Fair is fair!",
            already_run: 'Tests already run',
          },
        },
      },
      shared: {
        challenge: {
          one_ide: "You've run the exercise more than once",
          solution_modal: {
            my_review: 'My solution',
            open_solution: 'Check solution',
            title: "Teacher's solution",
            solution_explanation:
              "Teacher's solution is not perfect, it's just an example of a good, valid solution. Yours can be even better :)",
            no_solution: "This exercise doesn't include teacher's solution",
          },
          guide: {
            how_to_use: 'How to work in the code editor',
            about_ide:
              'What you are about to do is a practical exercise. You will do it in a real code editor and in a terminal with a shell running in it. This is the main working environment for a programmer. The place where programs are written, applications are created, and Web sites are created',
            next_slides:
              "On the following slides in this memo, we'll explain in detail how the editor is set up, where exactly to write code, and how to test it",
            about_memo:
              'The memo will help you understand the navigation and will always be handy in the menu bar',
            title: 'Editor overview',
            start_to_learn: 'Start learning',
          },
          solution: 'Solution',
          hint: 'Hint',
          ide: 'Memo',
          loading: 'Loading...',
          finish_modal: {
            similar_reviews: 'Related solutions',
            title: 'Exercise passed!',
            close: 'Stay here',
            next: 'Continue',
          },
          help_modal: {
            title: 'If you are stuck:',
            explanation:
              "<0>Exercises are based on previous lessons, so if what you've learned before isn't enough to solve the problem, it's our fault. Let us know your issue in the “Discussion” section, and we will improve a task description or enhance a theory. Either way, we'll get back to you.</0>" +
              '<1>To better understand the task:</1>' +
              '<2><0>Examine all of the exercise files. This will give you an idea of the code architecture and ways to run it.</0>' +
              '<1>Run tests. An error description will show what was expected and what actually happened.</1>' +
              "<2><0>Debug</0>. You'll see the code execution step by step.</2></2>" +
              "<3>If you've been stuck for an hour or more, don't see a solution, or can't handle a bug, read “Discussion”, and if you still have questions, create a topic. Use our “<0>How to ask questions properly</0>” guide when writing your question.</3>",
            open_solution: 'Check solution',
            no_solution: "This exercise doesn't include teacher's solution.",
            only_after_self_completion:
              "The teacher's solution will be available only after the task is completed on its own.",
            can_open_solution:
              "You'll get 0 points for this exercise if you look at the teacher's solution.",
            daily_limit_reached:
              "You've reached the daily limit of revealing teacher's solutions.",
            time_limit_run: "Teacher's solution will be available in",
          },
        },
      },
      lessons: {
        exercises: {
          instances: {
            container_loading: 'Container loading ({{ state }}) ...',
            ide_client_loading: 'Code editor loading ({{ state }}) ...',
            operational:
              'We are currently fixing some technical problems. Try to refresh the page later',
            memo_title: 'Did you know',
            memo: "that right now our system selects the most unloaded server for you and creates a virtual environment in which to run your code. This may take some time. But that's how you get full access to the machine to run databases, frameworks, and servers when you run a difficult exercise",
          },
        },
      },
      companies: {
        orders: {
          payment: {
            pay_for: 'Deposit to an internal account:',
            months: 'months',
          },
        },
      },
      subscriptions: {
        new: {
          cvc_header: 'How to find a CVC (CVV) Number?',
          cvc_explanation:
            "CVC (CVV) Number is located on the back of your credit/debit card. It's a 3 or 4-digit code near the signature strip",
        },
      },
    },
    simple_form: {
      placeholders: {
        subscription: {
          full_name: 'John Smith',
        },
      },
      errors: 'translation missing: ru.simple_form.errors',
      labels: {
        subscription: {
          card: 'Card number',
          expiration: 'Valid thru',
          cvc: 'CVC (CVV)',
          full_name: 'Cardholder name',
          terms_of_service_html:
            'I agreed to <a href="https://hexlet.io/pages/tos" class="text-decoration-none fw-bold" target="_blank" rel="nofollow">Service Conditions</a>',
        },
      },
    },
    stripe: {
      one_click_pay: 'or one-click purchase!',
    },
    notifications: {
      empty: 'There are no new notifications',
      description: 'Notifications',
      settings: 'Settings',
      close: 'Close',
    },

    qna: {
      flashes: {
        error:
          'Oops! Something went wrong. Probably a network problem. Try again.',
      },
      buttons: {
        voteUp: 'This answer is useful',
        voteDown: 'This answer is not useful',
        unVote: 'Take away vote',
      },
    },

    cloudPayments: {
      widgetLoadingError:
        'Error: Payment widget has not been loaded - try disabling your VPN or ad blocker',
      errors: {
        5001: 'Issuer declined the operation. Contact your bank or use another card',
        5003: 'Issuer declined the operation. Contact your bank or use another card',
        5004: 'Lost Card. Contact your bank or use another card',
        5005: 'Issuer declined without explanation. Contact your bank or use another card',
        5006: 'Network failure to perform an operation or incorrect CVV code. Check the correctness of entered card data or use another card',
        5007: 'Lost Card. Contact your bank or use another card',
        5012: 'The card is not intended for online payments. Contact your bank or use another card',
        5013: 'Too small or too large transaction amount. Check the correctness of the amount',
        5014: 'Invalid Card Number. Check the correctness of entered card data or use another card',
        5015: 'Unknown card issuer. Check the correctness of entered card data or use another card',
        5019: 'Issuer declined without explanation. Contact your bank or use another card',
        5030: 'Error on the side of the acquirer - incorrectly formed transaction. Try again later',
        5031: 'Unknown card issuer. Use another card',
        5033: 'Expired Card Pickup. Contact your bank or use another card',
        5034: 'Issuer failure - fraud is suspected. Contact your bank or use another card',
        5036: 'The card is not intended for online payments. Contact your bank or use another card',
        5041: 'Lost Card. Contact your bank or use another card',
        5043: 'Stolen Card. Contact your bank or use another card',
        5051: 'Insufficient Funds',
        5054: 'Card is expired or expiration date is incorrect. Check the correctness of entered card data or use another card',
        5057: 'Card limitation. Contact your bank or use another card',
        5059: 'Bank failure - fraud is suspected. Contact your bank or use another card',
        5062: 'The card is not intended for online payments. Contact your bank or use another card',
        5063: 'The card is blocked by security violation. Use another card',
        5065: 'Card transactions limit is exceeded. Contact your bank or use another card',
        5082: 'Incorrect CVV code. Check CVV code',
        5091: 'Issuer is not available. Try again later or use another card',
        5092: 'Issuer is not available. Try again later or use another card',
        5096: 'Acquiring bank or network error. Try again later',
        5204: 'The operation cannot be processed for other reasons. Contact your bank or use another card',
        5206: '3-D secure Authentication failed. Contact your bank or use another card',
        5207: '3-D secure Authentication unavailable. Contact your bank or use another card',
        5300: 'Acquirer Transaction Limits. Use another card',
      },
    },

    yookassa: {
      widgetLoadingError:
        'Error: Payment widget has not been loaded - try disabling your VPN or ad blocker',
    },
  },
};

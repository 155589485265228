export default {
  translation: {
    selectize: {
      clear_button: {
        title: 'Restablecer',
      },
    },
    autocomplete: {
      enterText: 'Escribe el texto…',
    },
    copyable: {
      copy_to_clipboard: 'Copiar',
      copied: '¡Copiado!',
    },
    typoreporter: {
      header: 'Informar de un error en la lección',
      messageLabel: 'El error está en el texto marcado en rojo:',
      commentLabel:
        '<div class="mb-3">Describe detalladamente el error para que podamos entender qué debemos mejorar</div><div class="mb-3">:warning: Si deseas que te respondamos en Mattermost cuando lo corrijamos, por favor, deja tu correo electrónico</div><strong class="mb-2">¿Enviar mensaje de error al editor del sitio?</strong>',
      send: '<strong>Enviar</strong>',
      sending: 'Enviando',
      cancel: 'Cancelar',
      error: '¡Error! Algo salió mal...',
    },
    links: {
      hexletHowToDebugHelpUrl:
        'https://soporte.codica.la/books/ejercicios/page/como-encontrar-errores-en-el-codigo',
      hexletAskQuestionsHelpUrl:
        'https://soporte.codica.la/books/general/page/como-hacer-una-pregunta-sobre-la-leccion',
      hexletCodeReviewHelpUrl:
        'https://soporte.codica.la/books/ejercicios/page/como-guardar-tu-solucion',
      hexletFaqHelpUrl:
        'https://soporte.codica.la/books/ejercicios/page/no-puedo-realizar-la-practica',
      hexletMarkdownGuideUrl: 'https://codica.la/guias/markdown',
      hexletErrorsLessonUrl:
        'https://app.codica.la/courses/codica_onboarding_course/lessons/errors/theory_unit',
      hexletMentorsQuestionsUrl:
        'https://app.codica.la/courses/codica_onboarding_course/lessons/mentor_questions/theory_unit',
      codicaSupportHowSendErrorUrl:
        'https://soporte.codica.la/link/88#bkmrk-error-en-el-sitio-we',
    },
    please_wait_html:
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="d-none d-md-inline">&nbsp;Espere...</span>',
    errors: {
      email: {
        invalid_format: 'Formato inválido',
      },
    },
    card_form: {
      errors: {
        payment_declined:
          'El pago fue rechazado por el banco. Esto sucede si no hay suficientes fondos en la cuenta o si su tarjeta no puede ser utilizada para pagos en línea',
        access: 'Es necesario iniciar sesión en su cuenta de Hexlet',
        network:
          'Ha ocurrido un error de red (código: {{code}}). Verifique su conexión a Internet y vuelva a intentarlo. Si el error persiste y tiene conexión a la red, haga clic en "Ayuda" y escríbanos',
      },
      fields: {
        card: {
          label: 'Datos de la tarjeta',
        },
        agreement: {
          label:
            'Acepto los <a href="https://codica.la/docs/terminos_y_condiciones_de_uso" target="_blank" rel="nofollow">términos de servicio</a>',
        },
        name: {
          label: 'Nombre',
          help: 'Como se indica en la tarjeta',
        },
      },
    },
    card: {
      on_success: {
        header: '¡Tarjeta cambiada con éxito!',
        explanation:
          'El próximo intento de retirar dinero utilizará la nueva tarjeta. No necesitas hacer nada más',
        next: 'Continuar',
      },
    },
    company_payment: {
      on_success: {
        header: '¡Factura pagada!',
        explanation: 'El dinero se acreditará en unos minutos',
        next: 'Continuar',
      },
      card_form: {
        fields: {
          submit: {
            label: 'Pagar {{amount}} {{currency.symbol}}',
          },
        },
      },
    },
    changing_card: {
      card_form: {
        fields: {
          submit: {
            label: 'Actualizar',
          },
        },
      },
    },
    subscription: {
      on_success: {
        header: '¡Felicidades!',
        explanation: 'Ahora tienes acceso completo a los materiales de Hexlet.',
        next: 'Comenzar a aprender',
      },
      card_form: {
        fields: {
          submit: {
            price_professional_label: {
              month: '{{amount}} por mes',
              year: '{{amount}} por año',
            },
            price_profession_label: {
              month: '{{amount}}',
              year: '{{amount}}',
            },
            price_premium_label: {
              month: '{{amount}} al mes',
              year: '{{amount}} al año',
            },
          },
        },
      },
    },

    community: {
      disabled: 'Tema cerrado',
      disabled_discription: 'Tema en archivo. Discusión en él cerrada',
      flashes: {
        error:
          '¡Ups! Algo salió mal. Puede haber problemas con la red. Inténtalo de nuevo.',
        mentor_assignment_conflict:
          'El tema está ocupado por otro mentor. Actualiza la página para ver los cambios',
      },
      validation: {
        required: 'Campo obligatorio',
      },
      buttons: {
        like: 'Me gusta',
        unlike: 'Ya no me gusta',
        usefull: 'Marcar como útil',
        markdown_help: 'Más sobre Markdown',
        how_to_ask_question: 'Cómo hacer preguntas correctamente',
        help: 'Problemas en la práctica',
        create_topic: 'Crear tema',
        unmark_as_solution: 'Cancelar',
        mark_as_solution: 'Marcar como solución',
        reply_to: 'Responder',
        cancel_edit_topic: 'cancelar',
        update_topic: 'guardar',
        delete_topic: 'Eliminar',
        cancel_remove_topic: 'Cancelar',
        apply_remove_topic: 'Eliminar',
        settings: 'Configuración',
        edit_topic: 'Editar',
        cancel: 'Cancelar',
        create_comment: 'Agregar',
        hide_comments: 'Ocultar comentarios',
        assign_mentor: 'Convertirse en mentor',
        unsubscribe_from_topic: 'Darse de baja',
        subscribe_to_topic: 'Suscribirse',
        show_comments: 'Mostrar más comentarios ({{count}})',
        save: 'Guardar',
        saving: 'Guardando...',
        saved: 'Guardado',
        link_to_github: 'Enlace a github',
      },
      log_in_for_adding_topic: 'Inicia sesión para comentar',
      placeholders: {
        write_question: 'Nuevo tema...',
        write_comment: 'Nuevo comentario...',
      },
      help: {
        code_review_html:
          'Si quieres hacer una pregunta sobre el ejercicio, guarda tu solución y proporciona un enlace a ella. El equipo de soporte no responde a preguntas sobre el código, pero te ayudará a entender el ejercicio o la salida de las pruebas',
      },
      mentor: 'Mentor: {{full_name}}',
      waiting_for_mentor_response: 'Esperando al mentor',
      marked_as_solution: 'Marcado como solución',
      ts: 'TS',
      new: 'Nuevo',
      mentor_title: 'Equipo de soporte de Hexlet',
      mentor_badge: 'Hexlet',
      hexlet_support_badge: 'Soporte',
      student_badge: 'Estudiante',
      hidden_topic:
        'Tema oculto, ya que el soporte técnico en "Discusiones" no realiza análisis o revisión de código',
      need_answer: 'Se necesita respuesta',
      need_answer_title:
        'En el recurso relacionado con el tema hay menos de 10 temas',
      topic_has_solution: 'Resuelto',
      ts_title: 'Iniciador del tema',
      ts_is_tutor: 'Tutor',
      ts_is_group_student: 'Estudiante de grupo asincrónico',
      ts_is_premium_student: 'Premium',
      ts_is_only_projects_student: 'En el grupo solo por proyectos',
      ts_has_base_subscription: 'Suscripción básica',
      choose_mentor: 'Asignar un mentor',
      question_kinds: {
        question: 'Pregunta',
        gratitude: 'Gratitud',
        common: 'Otro',
        bug: 'Informe de error',
        aggression: 'Agresión',
        code: 'Código',
        community: 'Comunidad',
      },
      question_mentorship_states: {
        opened: 'Abierto',
        closed: 'Cerrado',
      },
    },

    error: 'falta traducción: ru.error',
    block_of_code: 'Código (posible spoiler)',
    web: {
      communities: {
        btn: {
          expand: 'expandir',
          collapse: 'ocultar',
        },
      },

      courses: {
        lessons: {
          exercise_unit: {
            loading_error:
              'No se pudo establecer una conexión con el servidor de práctica. Ya sabemos del problema, intenta actualizar la página o <a data-confirm="{{ confirm }}" href="{{ link }}" class="alert-link" data-method="delete">realizar un reinicio.</a>',
            not_loading_html:
              'Si ocurre un fallo y el estado de carga cambia a <span class="fst-italic">failed</span>, <a data-confirm="{{ confirm }}" href="{{ link }}" class="link-light" data-method="delete">reinicia la práctica</a>',
            run: 'Comprobar',
            next: 'Siguiente',
            need_solution: 'Para avanzar, necesitas resolver el ejercicio',
            web_url: 'Acceso web',
            test_url: 'Test UI',
            confirm_reset:
              'Quieres restablecer el progreso del ejercicio. Tu código no se guardará. Si aún lo necesitas, cópialo en un bloc de notas. ¿Continuar con el reinicio?',
            confirm_view_solution:
              'Puedes echar un vistazo a la solución del profesor, pero no te daremos puntos por este ejercicio. Los puntos se otorgan solo por una solución correcta e independiente. ¡Todo es justo!',
            already_run: 'Las pruebas ya se están ejecutando',
          },
        },
      },
      shared: {
        challenge: {
          one_ide: 'Has iniciado la práctica más de una vez.',
          solution_modal: {
            my_review: 'Mi solución',
            open_solution: 'Mostrar solución',
            title: 'Solución del profesor',
            solution_explanation:
              'La solución del profesor no es el ideal al que debes aspirar, sino simplemente un ejemplo de una buena y correcta solución. Tu opción puede ser mejor :)',
            no_solution:
              'No se prevé una solución del profesor para este ejercicio.',
          },
          guide: {
            how_to_use: 'Cómo usar el editor de código',
            about_ide:
              'En Códica, harás los ejercicios en un editor de código con un terminal. Este es el lugar donde los programadores escriben código y crean aplicaciones o sitios web',
            next_slides:
              'En los siguientes slides de esta Guía de uso, te explicaremos cómo funciona el editor, dónde escribir el código y cómo revisarlo',
            about_memo:
              'Recuerda: Siempre puedes encontrar esta guía en el menú si la necesitas',
            title: 'Resumen del editor',
            start_to_learn: 'Empezar a aprender',
          },
          solution: 'Solución',
          hint: 'Ayuda',
          ide: 'Guía de uso',
          loading: 'Cargando...',
          finish_modal: {
            similar_reviews: 'Soluciones similares',
            title: '¡Ejercicio completado!',
            close: 'Quedarse aquí',
            next: 'Continuar',
          },
          help_modal: {
            title: '¿No puedes resolver el ejercicio? Sigue estos pasos:',
            explanation:
              '<0>' +
              '<0>Revisa los resultados de las pruebas. Si no sabes cómo interpretar los errores, consulta <0>esta lección</0>.</0>' +
              '<1>Si después de una hora sigues atascado, pregunta a tu <0>mentor en el chat grupal</0>.</1>' +
              '<2>Si detectas un problema con la tarea, haz clic en el botón “Tarea”, selecciona el error, presiona <0>Ctrl+Enter</0> y descríbelo. ¡Lo mejoramos! Más detalles <1>aquí</1>.</2>' +
              '</0>',
            open_solution: 'Mostrar solución',
            no_solution:
              'No se prevé una solución del profesor para este ejercicio.',
            only_after_self_completion:
              'La solución estará disponible solo después de completar la tarea.',
            can_open_solution:
              'Ver la solución te dará 0 puntos para este ejercicio.',
            daily_limit_reached:
              'Has alcanzado el límite diario de visualizaciones de soluciones de ejercicios.',
            time_limit_run: 'La solución estará disponible en',
          },
        },
        assignment: {
          solution_modal: {
            can_open_solution:
              'Ver la solución te dará 0 puntos para esta tarea.',
            daily_limit_reached:
              'Has alcanzado el límite diario de visualizaciones de soluciones de tareas.',
            time_limit_run: 'La solución estará disponible en',
            not_started:
              'Aún no has comenzado a hacer la tarea. Carga la tarea usando la utilidad de consola.',
          },
          finished_modal: {
            assignment_finished: '¡Tarea completada!',
            close: 'Cerrar',
          },
          hint: 'Pista',
          show_solution: 'Mostrar solución',
          help_modal: {
            title: 'Si no sabes qué hacer a continuación:',
            explanation:
              '<0>Las tareas se basan en lecciones anteriores, por lo que si el material previamente aprendido no es suficiente para resolver la tarea, es nuestra culpa. Escríbenos sobre esto en los comentarios, mejoraremos la descripción de la tarea y ampliaremos la teoría, y definitivamente te responderemos</0>' +
              '<1>Cómo entender mejor la tarea:</1>' +
              '<2><0>Estudia todos los archivos de la tarea. Te darán una idea de la arquitectura del código y cómo ejecutarlo</0>' +
              '<1>Ejecuta las pruebas. El texto del error mostrará lo que se esperaba y lo que se obtuvo</1>' +
              '<2>Usa <0>la impresión de depuración</0>. Te ayudará a ver los resultados de la ejecución del código paso a paso</2></2>' +
              '<3>Cuando te atasques, no veas opciones de solución o no puedas manejar el error, lee los comentarios y, si tienes preguntas, haz las tuyas. No te daremos la solución, pero te ayudaremos a entender la tarea o la salida de las pruebas. Al formular una pregunta, basa tu pregunta en nuestra guía "<0>Cómo hacer preguntas correctamente</0>"</3>',
            open_solution: 'Mostrar solución',
          },
        },
      },
      lessons: {
        exercises: {
          instances: {
            container_loading: 'Cargando contenedor ({{ state }}) ...',
            ide_client_loading: 'Cargando editor ({{ state }}) ...',
            operational:
              'Se están realizando trabajos técnicos en el sitio. Intenta actualizar la página más tarde',
            memo_title: '¿Sabías que:',
            memo: 'En este momento, nuestro sistema está seleccionando para ti el servidor menos cargado y creando un entorno virtual en el que se ejecutará tu código. Esto puede llevar algún tiempo. Pero es así como obtienes acceso completo a una máquina para ejecutar bases de datos, frameworks y servidores al realizar prácticas complejas',
          },
        },
      },
      companies: {
        orders: {
          payment: {
            pay_for: 'Acreditar a la cuenta interna:',
            months: 'meses',
          },
        },
      },
      subscriptions: {
        new: {
          cvc_header: '¿Dónde puedo encontrar el código CVC (CVV)?',
          cvc_explanation:
            'El código CVC (CVV) se puede encontrar en la parte posterior de la tarjeta. Son 3 o 4 dígitos junto a la firma del titular de la tarjeta',
          full_name_explanation:
            'Nombre exactamente como aparece en la tarjeta',
        },
      },
    },
    simple_form: {
      placeholders: {
        subscription: {
          full_name: 'Ivan Ivanov',
        },
      },
      errors: 'translation missing: ru.simple_form.errors',
      labels: {
        subscription: {
          card: 'Número de tarjeta',
          expiration: 'Válido hasta',
          cvc: 'CVC (CVV)',
          full_name: 'Nombre en la tarjeta',
          terms_of_service_html:
            'Acepto los <a href="https://codica.la/docs/terminos_y_condiciones_de_uso" target="_blank" rel="nofollow">términos de servicio</a>',
        },
      },
    },
    stripe: {
      one_click_pay: '¡o paga con un solo clic!',
    },
    notifications: {
      empty: 'No hay notificaciones',
      description: 'Alertas',
      settings: 'configuraciones',
    },
    lead_form: {
      header: '¡Vamos en la dirección correcta!',
      description1:
        'Parece que has estado con nosotros por un tiempo. ' +
        'Te familiarizaste con la teoría, aprendiste qué es la práctica, viste cómo funciona todo en Hexlet. ' +
        'Es hora de entender - ¿en qué lenguaje de programación deberías enfocarte? ¿Qué formato de aprendizaje deberías elegir?',
      description2:
        'Si ya has decidido todo, adelante. ' +
        'Si algo no está claro en la teoría o en las condiciones de la tarea, haz preguntas en las discusiones del curso, te ayudaremos a entender. ' +
        'Simplemente deja tu contacto si necesitas nuestro apoyo o consejo.',
      phone: {
        name: 'Teléfono',
        placeholder: 'Número de teléfono',
      },
      telegram: {
        name: 'Telegram',
        placeholder: 'Número de teléfono o nombre de usuario en Telegram',
      },
      whatsapp: {
        name: 'WhatsApp',
        placeholder: 'Número de teléfono',
      },
      how_to_communicate: '¿Cómo podemos contactarte?',
      get_consulted: 'Estaré esperando',
      success: '¡Todo salió bien!',
      we_will_call_you: 'Te llamaremos o escribiremos durante el día laboral',
      continue: 'Continuar aprendiendo',
      flashes: {
        error:
          '¡Ups! Algo salió mal. Puede que haya problemas con la red. Inténtalo de nuevo.',
      },
    },

    qna: {
      flashes: {
        error:
          '¡Ups! Algo salió mal. Puede que haya problemas con la red. Inténtalo de nuevo.',
      },
      buttons: {
        voteUp: 'Esta respuesta es útil',
        voteDown: 'Esta respuesta no es útil',
        unVote: 'Retirar voto',
      },
    },
  },
};

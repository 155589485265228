import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import ru from '../locales/ru.js';
import en from '../locales/en.js';
import es from '../locales/es.js';

i18next.use(initReactI18next).init({
  lng: window.gon.language,
  debug: window.gon.rails_env !== 'production',
  resources: {
    ru,
    en,
    es,
  },
  fallbackLng: {
    es: ['en'],
  },
});

export default i18next;

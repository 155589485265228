export default {
  translation: {
    selectize: {
      clear_button: {
        title: 'Сбросить',
      },
    },
    autocomplete: {
      enterText: 'Введите текст…',
    },
    copyable: {
      copy_to_clipboard: 'Скопировать',
      copied: 'Скопировано!',
    },
    typoreporter: {
      header: 'Сообщите об ошибке на странице',
      messageLabel: 'Ошибка содержится в следующем тексте:',
      commentLabel:
        '<div class="mb-3">Форма сообщения об ошибке односторонняя, мы не сможем ответить по ней. Если вам нужна обратная связь по теории урока или условиям заданий, задайте вопрос в «Обсуждениях»</div><strong class="mb-2">Отправить сообщение об ошибке редактору сайта?</strong>',
      send: '<strong>Отправить</strong>',
      sending: 'Отправляю',
      cancel: 'Отмена',
      error: 'Ошибка! Что-то пошло не так...',
    },
    links: {
      hexletHowToDebugHelpUrl: 'https://help.hexlet.io/article/20623',
      hexletAskQuestionsHelpUrl: 'https://help.hexlet.io/article/20559',
      hexletCodeReviewHelpUrl: 'https://help.hexlet.io/article/20538',
      hexletFaqHelpUrl: 'https://help.hexlet.io/article/20573',
      hexletMarkdownGuideUrl: 'https://guides.hexlet.io/ru/markdown/',
    },
    please_wait_html:
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="d-none d-md-inline">&nbsp;Подождите...</span>',
    errors: {
      email: {
        invalid_format: 'Неверный формат',
      },
    },
    card_form: {
      errors: {
        payment_declined:
          'Платёж отклонён банком. Такое случается, если на счету недостаточно средств или вашей картой нельзя платить в Интернете',
        access: 'Необходимо войти в свой аккаунт на Хекслете',
        network:
          'Возникла сетевая ошибка (код: {{code}}). Проверьте соединение с интернетом и попробуйте еще раз. Если ошибка будет повторяться, а сеть есть, нажмите на «Помощь» и напишите нам',
      },
      fields: {
        card: {
          label: 'Данные карты',
        },
        agreement: {
          label:
            'Я принимаю <a href="https://ru.hexlet.io/pages/offer" class="text-decoration-none fw-bold" target="_blank" rel="nofollow">публичную оферту</a>',
        },
        name: {
          label: 'Имя',
          help: 'Как указано на карте',
        },
      },
    },
    card: {
      on_success: {
        header: 'Карта успешно изменена!',
        explanation:
          'Следующая попытка списать деньги будет использовать новую карту. Вам больше ничего не нужно делать',
        next: 'Продолжить',
      },
    },
    company_payment: {
      on_success: {
        header: 'Счет оплачен!',
        explanation: 'Деньги зачислятся в течение нескольких минут',
        next: 'Продолжить',
      },
      card_form: {
        fields: {
          submit: {
            label: 'Оплатить {{amount}} {{currency.symbol}}',
          },
        },
      },
    },
    changing_card: {
      card_form: {
        fields: {
          submit: {
            label: 'Обновить',
          },
        },
      },
    },
    subscription: {
      on_success: {
        header: 'Поздравляем!',
        explanation: 'Теперь у вас есть полный доступ к материалам Хекслета.',
        next: 'Начать учиться',
      },
      card_form: {
        fields: {
          submit: {
            price_professional_label: {
              month: '{{amount}} в месяц',
              year: '{{amount}} в год',
            },
            price_profession_label: {
              month: '{{amount}}',
              year: '{{amount}}',
            },
            price_premium_label: {
              month: '{{amount}} в месяц',
              year: '{{amount}} в год',
            },
          },
        },
      },
    },

    community: {
      disabled: 'Топик закрыт',
      disabled_discription:
        'Этот топик был автоматически закрыт для комментирования, так как в нем была выявлена проблема, которая была успешно решена. Если у вас есть аналогичная проблема или вопрос, пожалуйста, создайте новый топик, и мы с удовольствием вам поможем! Спасибо за понимание.',
      flashes: {
        error:
          'Упс! Что-то пошло не так. Возможно проблемы с сетью. Попробуйте еще раз.',
        mentor_assignment_conflict:
          'Топик занят другим ментором. Обновите страницу, чтобы увидеть изменения',
      },
      validation: {
        required: 'Обязательное поле',
      },
      buttons: {
        like: 'Нравится',
        unlike: 'Больше не нравится',
        usefull: 'Отметить как полезный',
        markdown_help: 'Подробнее о Маркдауне',
        how_to_ask_question: 'Как правильно задавать вопросы',
        help: 'Проблемы в практике',
        create_topic: 'Создать топик',
        unmark_as_solution: 'Отменить',
        mark_as_solution: 'Отметить как решение',
        reply_to: 'Ответить',
        cancel_edit_topic: 'отмена',
        update_topic: 'сохранить',
        delete_topic: 'Удалить',
        cancel_remove_topic: 'Отмена',
        apply_remove_topic: 'Удалить',
        settings: 'Настройки',
        edit_topic: 'Редактировать',
        cancel: 'Отменить',
        create_comment: 'Добавить',
        hide_comments: 'Скрыть комментарии',
        assign_mentor: 'Стать ментором',
        unsubscribe_from_topic: 'Отписаться',
        subscribe_to_topic: 'Подписаться',
        show_comments: 'Показать ещё комментарии ({{count}})',
        save: 'Сохранить',
        saving: 'Сохранение...',
        saved: 'Сохранено',
        link_to_github: 'Ссылка на гитхаб',
      },
      log_in_for_adding_topic: 'Авторизуйтесь чтобы комментировать',
      placeholders: {
        write_question: 'Новый топик…',
        write_comment: 'Новый комментарий…',
      },
      help: {
        code_review_html:
          'Если хотите задать вопрос по упражнению, <a href="{{link}}" target="_blank" rel="noopener">сохраните ваше решение</a> и дайте ссылку на него. Команда поддержки не отвечает на вопросы по коду, но поможет разобраться с заданием или выводом тестов',
      },
      mentor: 'Ментор: {{full_name}}',
      waiting_for_mentor_response: 'Ожидает ментора',
      marked_as_solution: 'Отмечено как решение',
      ts: 'ТС',
      new: 'Новое',
      mentor_title: 'Команда поддержки Хекслета',
      mentor_badge: 'Hexlet',
      hexlet_support_badge: 'Поддержка',
      student_badge: 'Студент',
      hidden_topic:
        'Топик скрыт, так как техническая поддержка в «Обсуждениях» не делает разбор или ревью кода',
      need_answer: 'Требуется ответ',
      need_answer_title: 'В связанном с топиком ресурсе мненее 10 топиков',
      topic_has_solution: 'Решено',
      ts_title: 'Топикстартер',
      ts_is_tutor: 'Наставник',
      ts_is_group_student: 'Студент асинхронной группы',
      ts_is_optimal_group_student: 'Студент оптимальной группы',
      ts_is_standard_student: 'Студент базовой группы',
      ts_is_premium_student: 'Премиум',
      ts_is_only_projects_student: 'В группе только по проектам',
      ts_has_base_subscription: 'Базовая подписка',
      choose_mentor: 'Назначить ментора',
      question_kinds: {
        question: 'Вопрос',
        gratitude: 'Благодарность',
        common: 'Остальное',
        bug: 'Баг-репорт',
        aggression: 'Агрессия',
        code: 'Код',
        community: 'Комьюнити',
      },
      question_mentorship_states: {
        opened: 'Открыт',
        closed: 'Закрыт',
      },
    },

    error: 'translation missing: ru.error',
    block_of_code: 'Code (possible spoiler)',
    markdownEditor: {
      buttons: {
        edit: 'Правка',
        preview: 'Просмотр',
      },
    },
    web: {
      communities: {
        btn: {
          expand: 'развернуть',
          collapse: 'скрыть',
        },
      },

      courses: {
        lessons: {
          exercise_unit: {
            loading_error:
              'Не удалось установить соединение с сервером практики. Мы уже знаем о проблеме, попробуйте обновить страницу или <a data-confirm="{{ confirm }}" href="{{ link }}" class="alert-link" data-method="delete">выполнить сброс.</a>',
            not_loading_html:
              'Если случится сбой и статус загрузки изменится на <span class="fst-italic">failed</span>, <a data-confirm="{{ confirm }}" href="{{ link }}" class="link-light" data-method="delete">перезапустите практику</a>',
            run: 'Проверить',
            next: 'Далее',
            need_solution: 'Чтобы двигаться дальше, нужно решить упражнение',
            web_url: 'Web-доступ',
            test_url: 'Test UI',
            confirm_reset:
              'Вы хотите сбросить прогресс упражнения. Ваш код не сохранится. Если он вам еще потребуется, скопируйте его в блокнот. Продолжить сброс?',
            confirm_view_solution:
              'Вы можете подсмотреть решение учителя, но баллы за это упражнение мы не начислим. Баллы начисляются только за правильное самостоятельное решение. Все по-честному!',
            already_run: 'Тесты уже запущены',
          },
        },
      },
      shared: {
        challenge: {
          one_ide: 'Вы запустили практику более одного раза.',
          solution_modal: {
            my_review: 'Мое решение',
            open_solution: 'Показать решение',
            title: 'Решение учителя',
            solution_explanation:
              'Решение учителя — не идеал, к которому нужно стремиться, а лишь пример хорошего, правильного решения. Ваш вариант может быть лучше :)',
            no_solution: 'В этом задании не предусмотрено решение учителя.',
          },
          guide: {
            how_to_use: 'Как работать в редакторе кода',
            about_ide:
              'Сейчас вам предстоит выполнить практическое задание. Делать его вы будете в настоящем редакторе кода и терминале, с запущенной в нем командной оболочкой. Это и есть основная рабочая среда программиста. Место, где пишутся программы, создаются приложения, сайты',
            next_slides:
              'На следующих слайдах этой Памятки мы подробно объясняем, как устроен редактор, где именно писать код и как его проверять',
            about_memo:
              'Памятка поможет разобраться с навигацией и всегда будет под рукой на панели меню',
            title: 'Обзор редактора',
            start_to_learn: 'Начать учиться',
          },
          solution: 'Решение',
          hint: 'Подсказка',
          ide: 'Памятка',
          loading: 'Загрузка...',
          finish_modal: {
            similar_reviews: 'Похожие решения',
            title: 'Упражнение пройдено!',
            close: 'Остаться тут',
            next: 'Продолжить',
          },
          help_modal: {
            title: 'Если вы не знаете, что делать дальше:',
            explanation:
              '<0>Упражнения основаны на предыдущих уроках, поэтому если пройденного ранее материала недостаточно для решения задачи — это наша вина. Напишите об этом в обсуждениях, мы улучшим описание задачи и расширим теорию, и обязательно вам ответим</0>' +
              '<1>Как лучше понять задачу:</1>' +
              '<2>' +
              '<0>Изучите все файлы упражнения. Они дадут представление об архитектуре кода и способах его запуска</0>' +
              '<1>Запустите тесты. Текст ошибки покажет, что ожидалось и что получилось</1>' +
              '<2>Используйте <0>отладочную печать</0>. Она поможет увидеть результаты выполнения кода по шагам</2>' +
              '</2>' +
              '<3>Когда вы застряли, не видите вариантов решения или не можете справиться с ошибкой, почитайте обсуждения и, если останутся вопросы, задайте свой. Мы не будем подсказывать решение, но поможем разобраться с условием задания или выводом тестов. При составлении вопроса опирайтесь на наш гайд «<0>Как правильно задавать вопросы</0>»</3>' +
              '<4>Когда вы застряли и в течение часа не можете сдвинуться с места, не видите вариантов решения или не можете справиться с ошибкой, почитайте обсуждения и если останутся вопросы — обратитесь за помощью в <1>чат группы с вашим наставником</1>. При составлении вопроса опирайтесь на наш гайд «<0>Как правильно задавать вопросы</0>»</4>',
            open_solution: 'Показать решение',
            no_solution: 'В этом задании не предусмотрено решение учителя.',
            only_after_self_completion:
              'Решение будет доступно только после выполнения задания.',
            can_open_solution:
              'Просмотр решения принесёт 0 баллов за это упражнение.',
            daily_limit_reached:
              'Вы достигли суточного лимита по просмотрам решений упражнений.',
            time_limit_run: 'Решение станет доступно через',
          },
        },
        assignment: {
          solution_modal: {
            can_open_solution:
              'Просмотр решения принесёт 0 баллов за это домашнее задание.',
            daily_limit_reached:
              'Вы достигли суточного лимита по просмотрам решений домашних заданий.',
            time_limit_run: 'Решение станет доступно через',
            not_started:
              'Вы ещё не начали проходить домашнее задание. Загрузите домашнее задание с помощью консольной утилиты.',
          },
          finished_modal: {
            assignment_finished: 'Домашнее задание пройдено!',
            close: 'Закрыть',
          },
          hint: 'Подсказка',
          show_solution: 'Показать решение',
          help_modal: {
            title: 'Если вы не знаете, что делать дальше:',
            explanation:
              '<0>Домашние задания основаны на предыдущих уроках, поэтому если пройденного ранее материала недостаточно для решения задачи — это наша вина. Напишите об этом в обсуждениях, мы улучшим описание задачи и расширим теорию, и обязательно вам ответим</0>' +
              '<1>Как лучше понять задачу:</1>' +
              '<2><0>Изучите все файлы домашнего задания. Они дадут представление об архитектуре кода и способах его запуска</0>' +
              '<1>Запустите тесты. Текст ошибки покажет, что ожидалось и что получилось</1>' +
              '<2>Используйте <0>отладочную печать</0>. Она поможет увидеть результаты выполнения кода по шагам</2></2>' +
              '<3>Когда вы застряли, не видите вариантов решения или не можете справиться с ошибкой, почитайте обсуждения и, если останутся вопросы, задайте свой. Мы не будем подсказывать решение, но поможем разобраться с условием задания или выводом тестов. При составлении вопроса опирайтесь на наш гайд «<0>Как правильно задавать вопросы</0>»</3>',
            open_solution: 'Показать решение',
          },
        },
      },
      lessons: {
        exercises: {
          instances: {
            container_loading: 'Загрузка контейнера ({{ state }}) ...',
            ide_client_loading: 'Загрузка редактора ({{ state }}) ...',
            operational:
              'На сайте ведутся технические работы. Попробуйте обновить страницу позднее',
            memo_title: 'А знаете ли вы, что:',
            memo: 'Прямо сейчас наша система выбирает для вас самый незагруженный сервер и создает виртуальное окружение, в котором будет выполняться ваш код. Это может занять какое-то время. Но именно так вы получаете полный доступ к машине для запуска баз данных, фреймворков и серверов при выполнении сложной практики',
          },
        },
      },
      companies: {
        orders: {
          payment: {
            pay_for: 'Зачислить на внутренний счет:',
            months: 'месяцев',
          },
        },
      },
      subscriptions: {
        new: {
          cvc_header: 'Где посмотреть код CVC (CVV)?',
          cvc_explanation:
            'Код CVC (CVV) можно найти на обратной стороне карты. Это 3 или 4 цифры рядом с подписью держателя карты',
          full_name_explanation: 'Имя в точности как указано на карте',
        },
      },
    },
    simple_form: {
      placeholders: {
        subscription: {
          full_name: 'Ivan Ivanov',
        },
      },
      errors: 'translation missing: ru.simple_form.errors',
      labels: {
        subscription: {
          card: 'Номер карты',
          expiration: 'Действует до',
          cvc: 'CVC (CVV)',
          full_name: 'Имя на карте',
          terms_of_service_html:
            'Я принимаю <a href="https://ru.hexlet.io/pages/tos" target="_blank" rel="nofollow">условия использования</a> и <a href="https://ru.hexlet.io/pages/offer" target="_blank" rel="nofollow">публичную оферту</a>.',
        },
      },
    },
    stripe: {
      one_click_pay: 'или оплатите в один клик!',
    },
    notifications: {
      empty: 'Нет уведомлений',
      description: 'Уведомления',
      settings: 'настройки',
      close: 'Закрыть',
    },
    lead_form: {
      header: 'Идём верным курсом!',
      description1:
        'Похоже, вы с нами уже давно. ' +
        'Познакомились с теорией, узнали, что такое практика, увидели, как все устроено на Хекслете. ' +
        'Самое время понять – а что дальше? ' +
        'На каком языке программирования остановиться? Какой формат обучения выбрать?',
      description2:
        'Если вы уже все решили, в добрый путь. ' +
        'Если что-то непонятно по теории или условию задания, задавайте вопросы в обсуждениях к уроку — поможем разобраться' +
        'Просто оставьте контакт, если вам нужна наша поддержка или совет.',
      phone: {
        name: 'Телефон',
        placeholder: 'Номер телефона',
      },
      telegram: {
        name: 'Telegram',
        placeholder: 'Телефон или ник в Telegram',
      },
      whatsapp: {
        name: 'WhatsApp',
        placeholder: 'Номер телефона',
      },
      how_to_communicate: 'Как с вами связаться?',
      get_consulted: 'Буду ждать',
      success: 'Всё получилось!',
      we_will_call_you: 'Мы позвоним или напишем в течение рабочего дня',
      continue: 'Продолжить учиться',
      flashes: {
        error:
          'Упс! Что-то пошло не так. Возможно проблемы с сетью. Попробуйте еще раз.',
      },
    },

    qna: {
      flashes: {
        error:
          'Упс! Что-то пошло не так. Возможно проблемы с сетью. Попробуйте еще раз.',
      },
      buttons: {
        voteUp: 'Этот ответ полезен',
        voteDown: 'Этот ответ не полезен',
        unVote: 'Забрать голос',
      },
    },

    cloudPayments: {
      widgetLoadingError:
        'Ошибка: Платежный виджет не загрузился - попробуйте выключить VPN или блокировщик рекламы',
      errors: {
        5001: 'Отказ эмитента проводить онлайн-операцию. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5003: 'Отказ эмитента проводить онлайн-операцию. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5004: 'Карта потеряна. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5005: 'Отказ эмитента без объяснения причин. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5006: 'Отказ сети проводить операцию или неправильный CVV-код. Проверьте правильность введенных данных карты или воспользуйтесь другой картой',
        5007: 'Карта потеряна. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5012: 'Карта не предназначена для онлайн-платежей. Воспользуйтесь другой картой или свяжитесь с банком, выпустившим карту',
        5013: 'Слишком маленькая или слишком большая сумма операции. Проверьте корректность суммы',
        5014: 'Некорректный номер карты. Проверьте правильность введенных данных карты или воспользуйтесь другой картой',
        5015: 'Эмитент не найден. Проверьте правильность введенных данных карты или воспользуйтесь другой картой',
        5019: 'Отказ эмитента без объяснения причин. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5030: 'Ошибка на стороне эквайера — неверно сформирована транзакция. Повторите попытку позже',
        5031: 'Неизвестный эмитент карты. Воспользуйтесь другой картой',
        5033: 'Истек срок утери карты. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5034: 'Отказ эмитента — подозрение на мошенничество. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5036: 'Карта не предназначена для платежей. Платежи для этой карты запрещены. Попробуйте другую карту',
        5041: 'Карта потеряна. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5043: 'Карта украдена. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5051: 'Недостаточно средств на карте',
        5054: 'Карта просрочена или неверно указан срок действия. Проверьте правильность введенных данных карты или воспользуйтесь другой картой',
        5057: 'Ограничение на карте. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5059: 'Транзакция была отклонена банком по подозрению в мошенничестве. Свяжитесь с банком или воспользуйтесь другой картой',
        5062: 'Карта не предназначена для платежей. Платежи для этой карты запрещены. Попробуйте другую карту',
        5063: 'Карта заблокирована из-за нарушений безопасности. Воспользуйтесь другой картой',
        5065: 'Превышен лимит операций по карте. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5082: 'Неверный CVV-код. Неверно указан код CVV',
        5091: 'Эмитент недоступен. Повторите попытку позже или воспользуйтесь другой картой',
        5092: 'Эмитент недоступен. Повторите попытку позже или воспользуйтесь другой картой',
        5096: 'Ошибка банка-эквайера или сети. Повторите попытку позже',
        5204: 'Операция не может быть обработана по прочим причинам. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5206: '3-D Secure авторизация не пройдена. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5207: '3-D Secure авторизация недоступна. Свяжитесь с вашим банком или воспользуйтесь другой картой',
        5300: 'Лимиты эквайера на проведение операций. Воспользуйтесь другой картой',
      },
    },

    yookassa: {
      widgetLoadingError:
        'Ошибка: Платежный виджет не загрузился - попробуйте выключить VPN или блокировщик рекламы',
    },
  },
};
